import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
// import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import { nextPage, previousPage } from "../utils/pagination";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";

const DocumentAchat = () => {
    const abortController = useMemo(() => new AbortController(), []);
    const [dataAchat, setdataAchat] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const ELEMENET_PER_PAGE = 20;

    const ListeAchats = useCallback(async () => {
        const results = await Api.get(
            "recouv/datadocumentuser/",
            abortController.signal
        );
        if (results.results) {
            setLoading(false);
        }

        // console.log("dataAchat", results);
        setdataAchat(results);

        const PAGE_NUMBER = Math.ceil(results.count / ELEMENET_PER_PAGE);

        setTotalPage(PAGE_NUMBER);
    }, [abortController]);

    const handleNextPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await nextPage(params);
            setPage(page + 1);
            setdataAchat(datas);
            // dataHistory.next === null ? setIsDisabled(true) : setIsDisabled(false);
        }
        setLoading(false);
    };

    const handlePreviousPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await previousPage(params);
            page > 0 ? setPage(page - 1) : setPage(1);
            setdataAchat(datas);
            // dataHistory.previous === null
            //   ? setIsDisabled(true)
            //   : setIsDisabled(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        ListeAchats();
    }, [ListeAchats]);

    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-6">
                <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                    {loading ? (
                        <Skeleton count={20} />
                    ) : dataAchat && dataAchat.results.length ? (
                        <>
                            <table className="table table-report sm:mt-2">
                                <thead className=" text-gray-800">
                                    <tr className="">
                                        <th className="whitespace-no-wrap">CLIENTS</th>
                                        <th className="whitespace-no-wrap">DOCUMENTS</th>
                                        <th className="text-center whitespace-no-wrap">
                                            DESCRIPTIONS
                                        </th>
                                        <th className="text-center whitespace-no-wrap">
                                            ENTREPRISES
                                        </th>
                                        <th className="text-center whitespace-no-wrap">MONTANTS</th>
                                        <th className="text-center whitespace-no-wrap">
                                            MOYEN DE PAIEMENT
                                        </th>
                                        <th className="text-center whitespace-no-wrap">
                                            DATE D'ACHAT
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataAchat.results.map((item, index) => {
                                        return (
                                            <tr className="intro-x" key={index}>
                                                {/* <td>
                                                  <div className="text-gray-800 text-md whitespace-no-wrap">12/01/2024</div>
                                              </td> */}
                                                <td className="">
                                                    <div className="flex  text-gray-800">
                                                        {item.user.email}
                                                    </div>
                                                </td>

                                                <td className=" ">
                                                    <div className="text-gray-800">
                                                        {String.stringLength(item.datadocument.file_name)}
                                                        {/* {styleDate(item.created_at)} */}
                                                    </div>
                                                </td>

                                                <td className=" ">
                                                    <div className="text-gray-800">
                                                        {String.stringLength(item.datadocument.name)}
                                                        {/* {styleDate(item.created_at)} */}
                                                    </div>
                                                </td>
                                                <td className=" ">
                                                    <div className="flex justify-center items-center text-center text-gray-800">
                                                        {item.datadocument.datacompany.data.denomination}
                                                    </div>
                                                </td>

                                                <td className=" ">
                                                    <div className="text-gray-800">
                                                        {item.datadocument.data.length
                                                            ? item.datadocument.data[0].total
                                                            : 0}
                                                    </div>
                                                </td>

                                                <td className=" ">
                                                    <div className="text-gray-800">
                                                        {String.stringLength(
                                                            item.payment.payment_method.name
                                                        )}
                                                    </div>
                                                </td>

                                                <td className=" ">
                                                    <div className="text-gray-800">
                                                        {styleDate(item.created_at)}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                className={
                                    dataAchat.count > 20
                                        ? "px-5 py-3 flex justify-end text-right border-t border-gray-200 "
                                        : "hidden"
                                }
                            >
                                <button
                                    onClick={() => handlePreviousPage(dataAchat.previous)}
                                    type="button"
                                    className="button  flex w-36 items-center  border bg-theme-1 text-white "
                                >
                                    <span>
                                        <Icons.ArrowLeft />
                                    </span>
                                    <span> Precedent </span>
                                </button>
                                <div className="bg-white text-gray-600 bold mx-5 p-3 box ">
                                    {dataAchat.results.length ? (
                                        <span>
                                            {page} / {totalPage}
                                        </span>
                                    ) : (
                                        0
                                    )}
                                </div>
                                <button
                                    onClick={() => handleNextPage(dataAchat.next)}
                                    type="button"
                                    className="button  flex w-36 items-center  border bg-theme-1 text-white"
                                >
                                    <span> Suivant </span>
                                    <span>
                                        <Icons.ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="intro-y bg-white p-5  text-center text-3xl text-gray-600 mt-3">
                            Désolé pas d'information disponible
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentAchat;
