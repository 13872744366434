import { Link, NavLink, useLocation } from "react-router-dom";
import logoImg from "../assets/images/Logo_DATA_GREFFE.png";
import * as Icons from "react-feather";
import { Auth } from "../utils/Auth";
import { useState, useEffect } from "react";

export function SideNav() {
  const { pathname } = useLocation();
  const { role } = Auth.getUser(); // Récupération du rôle

  const [openMenu, setOpenMenu] = useState(null);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const activeClassName = "side-menu--active";
  const dynamicClassName = ({ isActive }) =>
    isActive ? `side-menu ${activeClassName}` : "side-menu";

  const menuConfig = {
    clients: {
      title: "Clients",
      icon: <Icons.Users />,
      subMenus: {
        organisation: {
          path: "/Organisation",
          title: "Organisations",
          icon: <Icons.Globe />
        },
        client: {
          path: "/Client",
          title: "Particuliers",
          icon: <Icons.User />
        }
      }
    },
    achats: {
      title: "Achats",
      icon: <Icons.ShoppingCart />,
      subMenus: {
        utilisateurs: {
          path: "/Achat-Utilisateurs",
          title: "Achat Particuliers",
          icon: <Icons.UserCheck />
        },
        organisation: {
          path: "/Achat-Organisation",
          title: "Achat Organisation",
          icon: <Icons.Package />
        }
      }
    }
  };

  useEffect(() => {
    Object.entries(menuConfig).forEach(([menuKey, menuData]) => {
      Object.entries(menuData.subMenus).forEach(([subMenuKey, subMenuData]) => {
        if (pathname === subMenuData.path) {
          setActiveMenuItem({
            menuKey,
            subMenuKey,
            ...subMenuData
          });
        }
      });
    });
  }, [pathname]);

  const toggleMenu = (menuName) => {
    if (openMenu === menuName) {
      setOpenMenu(null);
    } else {
      setOpenMenu(menuName);
    }
  };

  const handleSubMenuClick = (menuKey, subMenuKey, subMenuData) => {
    if (activeMenuItem?.menuKey === menuKey && activeMenuItem?.subMenuKey === subMenuKey) {
      setOpenMenu(menuKey);
    } else {
      setActiveMenuItem({
        menuKey,
        subMenuKey,
        ...subMenuData
      });
      setOpenMenu(null);
    }
  };

  const handleActiveMenuClick = (e, menuKey) => {
    e.preventDefault();
    toggleMenu(menuKey);
    if (openMenu === menuKey) {
      setActiveMenuItem(null);
    }
  };

  return (
    <nav className="side-nav">
      <Link to="/" className="intro-x text-center relative z-10">
        <img
          alt="Midone Tailwind HTML Admin Template"
          className="w-20 mx-auto mt-3"
          src={logoImg}
        />
      </Link>
      <div className="side-nav__devider my-6"></div>
      <ul>
        <li>
          <NavLink to="/Alert" className={dynamicClassName}>
            <div className="side-menu__icon">
              <Icons.Bell />
            </div>
            <div className="side-menu__title">Alertes</div>
          </NavLink>
        </li>

        {/* Vérifiez si l'utilisateur est un Admin avant de rendre le menu */}
        {role === "Admin" || role === "admin" &&
          Object.entries(menuConfig).map(([menuKey, menuData]) => (
            <li key={menuKey}>
              {activeMenuItem?.menuKey === menuKey && !openMenu ? (
                <NavLink
                  to={activeMenuItem.path}
                  className={dynamicClassName}
                  onClick={(e) => handleActiveMenuClick(e, menuKey)}
                >
                  <div className="side-menu__icon">
                    {activeMenuItem.icon}
                  </div>
                  <div className="side-menu__title">
                    {activeMenuItem.title}
                    <div className="side-menu__sub-icon">
                      <Icons.ChevronDown />
                    </div>
                  </div>
                </NavLink>
              ) : (
                <>
                  <div
                    className={`side-menu ${openMenu === menuKey ? activeClassName : ''}`}
                    onClick={() => toggleMenu(menuKey)}
                  >
                    <div className="side-menu__icon">
                      {menuData.icon}
                    </div>
                    <div className="side-menu__title">
                      {menuData.title}
                      <div className={`side-menu__sub-icon ${openMenu === menuKey ? 'transform rotate-180' : ''}`}>
                        <Icons.ChevronDown />
                      </div>
                    </div>
                  </div>
                  {openMenu === menuKey && (
                    <ul className="side-menu__sub-open" style={{ backgroundColor: 'white' }}>
                      {Object.entries(menuData.subMenus).map(([subMenuKey, subMenuData]) => (
                        <li key={subMenuKey}>
                          <NavLink
                            to={subMenuData.path}
                            className={dynamicClassName}
                            onClick={() => handleSubMenuClick(menuKey, subMenuKey, subMenuData)}
                          >
                            <div className="side-menu__icon text-gray-700">
                              {subMenuData.icon}
                            </div>
                            <div className="side-menu__title text-gray-700">
                              {subMenuData.title}
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </li>
          ))
        }

        <li>
          <NavLink to="/Document-Acheter" className={dynamicClassName}>
            <div className="side-menu__icon">
              <Icons.Folder />
            </div>
            <div className="side-menu__title">Documents Achetés</div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/Recherche" className={dynamicClassName}>
            <div className="side-menu__icon">
              <Icons.Search />
            </div>
            <div className="side-menu__title">Historique de Recherches</div>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
