import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
// import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { nextPage, previousPage } from "../utils/pagination";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Utils } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



const CreationOrganisation = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [rccm, setRccm] = useState("");
    const [site, setSite] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [contrat, setContrat] = useState(null); // Pour gérer le fichier PDF
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();  // Initialisation de useNavigate


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
        } else {
            setFileName('');
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        setLoading(true);
        const data = {
            name,
            description,
            rccm,
            site,
            contact,
            email,
            contrat: contrat ? contrat.name : null,
        };

        try {
            const response = await fetch("https://api.datagreffe.ci/recouv/company/", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Erreur lors de la création de l'organisation: ${errorData.detail || 'Erreur inconnue'}`);
            }
            const result = await response.json();
            toast.success("Organisation créée avec succès!");

            navigate(-1);
        } catch (error) {
            console.error("Erreur:", error);
            toast.error(error);

        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="p-4">
            <form >
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-black">Nom</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Description</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">RCCM</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={rccm}
                            onChange={(e) => setRccm(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Site</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={site}
                            onChange={(e) => setSite(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-black">Contact</label>
                        <PhoneInput
                            country={"ci"} // Définit la Côte d'Ivoire par défaut
                            value={contact}
                            onChange={setContact}
                            inputClass="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            containerClass="w-full"
                            inputStyle={{ width: "100%" }}
                            enableSearch={true} // Permet la recherche de pays
                            dropdownStyle={{ color: "black", backgroundColor: "white" }} // Texte de la liste en noir
                            searchStyle={{ color: "black" }} // Texte de la recherche en noir
                            enableLongNumbers={true}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Email</label>
                        <input
                            type="email"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Contrat</label>
                        <button
                            type="button"
                            onClick={() => document.getElementById('fileInput').click()}
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                        >
                            Charger un fichier
                        </button>
                        <input
                            id="fileInput"
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {fileName && (
                            <div className="mt-2 text-orange-500">
                                Fichier chargé : {fileName}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-700"
                        disabled={loading}
                    >
                        {loading ? <Skeleton width={100} /> : "Créer l'organisation"}
                    </button>
                </div>
            </form>
            <ToastContainer />

        </div>
    );
};

export default CreationOrganisation;
