import "../assets/css/app.css";

// import { useNavigate } from 'react-router-dom';
import { MobileMenu } from "../components/MobileMenu";
import { SideNav } from "../components/SideNav";
import { TopBar } from "../components/TopBar";
import Client from "../components/Client";
import Achat from "../components/Achat";
import Recherche from "../components/Recherche";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Utils } from "../utils";
import Alert from "../components/Alert";
import DocumentAchat from "../components/DocumentAcheter";
import Notifications from "../components/Notification";
import Organisation from "../components/Organisations";
import CreationOrganisation from "../components/CreationOrganisations";
import CreationUtilisateur from "../components/CreationUtlisateur";
import ListUtilisateur from "../components/ListUtilisateur";
import HistoriqueOrganisation from "../components/HistoriqueOrganisation";
import CrediterOrganisation from "../components/CrediterOrganisation";
import AchatOrganisation from "../components/AchatOrganisation";
import EditOrganisationCollab from "../components/EditOrganisatioCollab";
import ParametreFacturation from "../components/ParametreFacturations";


export default function MainLayout(props) {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const params = location.split("/"); // Divise l'URL en segments
  const routeName = params[1]; // Premier segment après le slash
  const routeId = params[2]; // Deuxième segment, si présent

  useEffect(() => {
    if (!Utils.Auth.isLoggedIn()) navigate("/");
  }, [navigate]);

  return (
    <>
      <MobileMenu />
      <div className="flex">
        <SideNav />
        <div className="content">
          <TopBar />

          {(() => {
            switch (routeName) {
              case "Alert":
                return <Alert />;
              case "notification":
                // Si un id est présent, passez-le au composant Notifications
                return <Notifications id={routeId} />;
              case "Document-Acheter":
                return <DocumentAchat />;
              case "Creation-Organisation":
                return <CreationOrganisation />;
              case "Crediter-Organisation":
                return <CrediterOrganisation />;
              case "Historique-Organisation":
                return <HistoriqueOrganisation id={routeId} />;
              case "Creation-Utilisateur":
                return <CreationUtilisateur id={routeId} />;
              case "Edition-Utilisateur":
                return <EditOrganisationCollab />;
              case "Liste-Utilisateur":
                return <ListUtilisateur id={routeId} />;
              case "Parametre-Facturation":
                return <ParametreFacturation />;
              case "Client":
                return <Client />;
              case "Organisation":
                return <Organisation />;
              case "Achat-Utilisateurs":
                return <Achat />;
              case "Achat-Organisation":
                return <AchatOrganisation />;
              case "Recherche":
                return <Recherche />;
              default:
                return (
                  <div className="text-theme-11 text-3xl">
                    Indisponible pour le moment
                  </div>
                );
            }
          })()}

          {props.children}
        </div>
      </div>
    </>
  );
}