import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { nextPage, previousPage } from "../utils/pagination";
import { Utils } from "../utils";

const Client = () => {
  const abortController = useMemo(() => new AbortController(), []);
  const [dataUsers, setdataUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const ELEMENET_PER_PAGE = 10;

  const [searchTerm, setSearchTerm] = useState("");

  const listUsers = useCallback(async () => {
    const results = await Api.get("recouv/users/", abortController.signal);
    setClients(results.results);

    if (results) {
      setLoading(false);
    }

    setdataUsers(results);
    setFilteredUsers(results.results);

    const PAGE_NUMBER = Math.ceil(results.count / ELEMENET_PER_PAGE);
    setTotalPage(PAGE_NUMBER);
  }, [abortController]);

  const handleNextPage = async (params) => {
    setLoading(true);
    if (params) {
      const datas = await nextPage(params);
      setPage(page + 1);
      setdataUsers(datas);
      setFilteredUsers(datas.results);
      setClients(datas.results);
    }
    setLoading(false);
  };

  const handlePreviousPage = async (params) => {
    setLoading(true);
    if (params) {
      const datas = await previousPage(params);
      page > 0 ? setPage(page - 1) : setPage(1);
      setdataUsers(datas);
      setFilteredUsers(datas.results);
      setClients(datas.results);
    }
    setLoading(false);
  };

  const resetToInitialState = () => {
    setIsSearching(false);
    setClients(dataUsers.results || []);
  };

  const handleSearchChange = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm.trim() !== "") {
      setIsSearching(true);
    } else {
      resetToInitialState();
    }
  };

  const handleSearch = async () => {
    if (searchTerm.trim() !== "") {
      setIsSearching(true);
      setLoading(true);
      try {
        const url = `https://api.datagreffe.ci/recouv/users/?name=${searchTerm}`;
        const response = await fetch(url, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }

        const data = await response.json();
        setClients(data.results || []);
      } catch (error) {
        console.error("Erreur lors de la recherche :", error);
      } finally {
        setLoading(false);
      }
    } else {
      resetToInitialState();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSearchTerm("");
      resetToInitialState();
    } else if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    listUsers();
  }, [listUsers]);

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 mt-6">
        <div className="intro-y">
          {/* Barre de recherche */}
          <div className="flex items-center gap-2 my-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                className="input w-48 p-2 text-black rounded-lg focus:ring-2 focus:ring-theme-1 placeholder-gray-500"
                placeholder="Rechercher par email"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
              />
              <button
                className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                onClick={handleSearch}
              >
                Rechercher
              </button>
            </div>
          </div>
          <div className="overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            {loading ? (
              <Skeleton count={20} />
            ) : clients.length > 0 ? (
              <>
                <table className="table table-report sm:mt-2">
                  <thead className="text-gray-800">
                    <tr>
                      <th className="whitespace-no-wrap">NOM & PRENOMS</th>
                      <th className="whitespace-no-wrap">CONTACTS</th>
                      <th className="text-center whitespace-no-wrap">MAIL</th>
                      <th className="text-center whitespace-no-wrap">
                        DATE D'INSCRIPTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((item, index) => {
                      return (
                        <tr
                          className="intro-x"
                          key={index}
                          data-testid="liste-client"
                        >
                          <td className="text-start">
                            <div className="text-gray-800">
                              {item.last_name} {item.first_name}
                            </div>
                          </td>
                          <td className="">
                            <div className="text-gray-800">
                              {item.phone
                                ? item.phone
                                : "Aucun numero enregistré"}
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="text-gray-800">{item.email}</div>
                          </td>
                          <td className="text-center">
                            <div className="flex justify-center items-center text-gray-800">
                              {styleDate(item.created_at)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* Pagination conditionnelle: affichée uniquement lorsqu'on n'est pas en mode recherche */}
                {!isSearching && dataUsers.count > 20 && (
                  <div className="px-5 py-3 flex justify-end text-right border-t border-gray-200">
                    <button
                      onClick={() => handlePreviousPage(dataUsers.previous)}
                      type="button"
                      className="button flex w-36 items-center border bg-theme-1 text-white"
                      disabled={!dataUsers.previous}
                    >
                      <span>
                        <Icons.ArrowLeft />
                      </span>
                      <span> Précédent </span>
                    </button>
                    <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                      <span>
                        {page} / {totalPage}
                      </span>
                    </div>
                    <button
                      onClick={() => handleNextPage(dataUsers.next)}
                      type="button"
                      className="button flex w-36 items-center border bg-theme-1 text-white"
                      disabled={!dataUsers.next}
                    >
                      <span> Suivant </span>
                      <span>
                        <Icons.ArrowRight />
                      </span>
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="intro-y bg-white p-5 text-center text-3xl text-gray-600 mt-3">
                Aucun utilisateur trouvé.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;


