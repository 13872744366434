import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../assets/images/Logo_DATA_GREFFE.png";
import { AuthService } from "../../services/AuthService";
import ErrorMessages from "../../components/ErrorMessages";
import Swal from "sweetalert2";


const ForgetPasswordView = () => {
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const abortController = new AbortController();
  const navigate = useNavigate();

  const handleMailSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages([]);
    setIsDisabled(true);

    try {
      const payload = {
        email,
      };

      await AuthService.mail(JSON.stringify(payload), abortController.signal);
      setIsDisabled(false);
      setEmail("");
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Veuillez consulter vos mail pour renouveler votre mot de passe`,
        showConfirmButton: false,
        timer: 2000,
      }).then((result) => {
        navigate("/password-reset-confirm/:uid/:token/");
      });



    } catch (error) {
      setIsDisabled(false);
      setErrorMessages();
    }
  };
  return (
    <form name="loginForm" id="loginForm" onSubmit={handleMailSubmit}>
      <div className="login">
        <div className="container ">
          <div className="block xl:grid grid-cols-2 gap-4">
            <div className="hidden xl:flex flex-col min-h-screen">
              <div className="my-auto">
                <Link to="" className="-intro-x flex mx-20 items-center">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    className="w-80 h-64"
                    src={logoImg}
                  />
                </Link>

                <div className="-intro-x text-white font-medium text-xl leading-tight mt-10">
                  Accédez a toutes les informations légales,
                  <br /> juridiques et financières sur les entreprises de votre
                  choix.
                </div>
                <div className="-intro-x mt-8 text-md text-white dark:text-gray-500">
                  Gérez tous vos documents achetés en un seul endroit.
                </div>
              </div>
            </div>

            <div className="h-screen xl:h-auto flex ">
              <div className="my-auto mx-auto  bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <ErrorMessages>{errorMessages}</ErrorMessages>

                <h2 className="intro-x font-bold text-2xl text-theme-11 xl:text-3xl text-center ">
                  Votre e-mail de réinitialisation
                </h2>

                <div className="intro-x mt-8">
                  <input
                    type="email"
                    className="intro-x w-full xl:w-full input input--lg text-gray-700 border border-gray-300 block"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isDisabled}
                    required
                  />
                </div>

                <div className="intro-x mt-5 xl:mt-8 text-center ">
                  {/* <Link
                  to="/reinit-mot-de-passe"
                  className="-intro-x flex items-center pt-5"
                > */}
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="button button--lg w-full xl:w-full text-white bg-theme-11 align-top"
                  >
                    {isDisabled ? "Chargement" : "Envoyer"}
                  </button>
                  {/* </Link> */}
                </div>

                <div className="intro-x mt-5 xl:mt-5 text-center ">
                  <Link to="/" className="-intro-x flex items-center pt-5">
                    <button className="button button--lg w-full xl:w-full text-gray-600  bg-theme-5 align-top">
                      Se connecter
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ForgetPasswordView;
