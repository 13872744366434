import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
// import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { nextPage, previousPage } from "../utils/pagination";
import { NOTIFICATION } from "../utils/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../utils";

const Alert = () => {
    const abortController = useMemo(() => new AbortController(), []);
    const [alerts, setAlerts] = useState([]);
    const [filteredAlerts, setFilteredAlerts] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const ELEMENT_PER_PAGE = 20;

    const [filter, setFilter] = useState("actifs");
    const [searchQuery, setSearchQuery] = useState("");

    const fetchAlerts = useCallback(async () => {
        setLoading(true);
        try {
            // Par défaut, récupérer uniquement les alertes actives
            const url = `notifications/alerts/?is_active=true`;
            const results = await Api.get(url, abortController.signal);

            if (results) {
                const PAGE_NUMBER = Math.ceil(results.count / ELEMENT_PER_PAGE);
                setTotalPage(PAGE_NUMBER);
                setAlerts(results); // Stocke toutes les alertes récupérées
                setFilteredAlerts(results.results); // Filtre les alertes
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des alertes :", error);
        }
        setLoading(false);
    }, [abortController]);



    const handleNextPage = async (params) => {
        setLoading(true);
        if (params) {
            try {
                // Ajout du filtre actif/inactif
                const isActive = filter === "actifs"; // Détermine le filtre actif
                const url = `${params}&is_active=${isActive}`;

                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                    signal: abortController.signal,
                });

                const data = await response.json(); // Convertir la réponse en JSON

                if (response.ok) {
                    setPage(page + 1); // Incrémenter la page
                    setAlerts(data); // Met à jour les alertes globales
                    setFilteredAlerts(data.results); // Met à jour les alertes affichées
                } else {
                    console.error("Erreur de récupération des alertes :", data);
                }
            } catch (error) {
                console.error("Erreur de réseau ou autre :", error);
            }
        }
        setLoading(false);
    };

    const handlePreviousPage = async (params) => {
        setLoading(true);
        if (params) {
            try {
                // Ajout du filtre actif/inactif
                const isActive = filter === "actifs"; // Détermine le filtre actif
                const url = `${params}&is_active=${isActive}`;

                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                    signal: abortController.signal,
                });

                const data = await response.json(); // Convertir la réponse en JSON

                if (response.ok) {
                    setPage(page > 1 ? page - 1 : 1); // Décrémenter la page ou rester sur 1
                    setAlerts(data); // Met à jour les alertes globales
                    setFilteredAlerts(data.results); // Met à jour les alertes affichées
                } else {
                    console.error("Erreur de récupération des alertes :", data);
                }
            } catch (error) {
                console.error("Erreur de réseau ou autre :", error);
            }
        }
        setLoading(false);
    };


    const handleFilterChange = async (status) => {
        setFilter(status); // Met à jour l'état du filtre
        setLoading(true); // Active le loader pendant la récupération des données

        try {
            // Définir le filtre actif/inactif
            const isActive = status === "actifs";
            const url = `notifications/alerts/?is_active=${isActive}`;

            // Appeler l'API
            const results = await Api.get(url);

            if (results) {
                setAlerts(results); // Met à jour toutes les alertes récupérées
                setFilteredAlerts(results.results || []); // Met à jour les alertes filtrées
                setPage(1); // Réinitialise la pagination
                const PAGE_NUMBER = Math.ceil(results.count / ELEMENT_PER_PAGE);
                setTotalPage(PAGE_NUMBER); // Met à jour le nombre total de pages
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des alertes :", error);
        }

        setLoading(false); // Désactive le loader
    };




    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query) {
            try {
                const url = `https://api.datagreffe.ci/notifications/alerts/?rccm_entreprise=${query}`;
                const abortController = new AbortController();
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                    signal: abortController.signal,
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setFilteredAlerts(data.results || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setFilteredAlerts(alerts.results);
        }
    };


    const handleSearchSubmit = async () => {
        if (searchQuery) {
            try {
                const url = `https://api.datagreffe.ci/notifications/alerts/?rccm_entreprise=${searchQuery}`;
                const abortController = new AbortController();
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`
                    },
                    signal: abortController.signal
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setFilteredAlerts(data.results || []);
                console.log("Résultats de la recherche : ", data.results);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            console.log("Aucune recherche effectuée, réinitialisation des résultats.");
            setFilteredAlerts(alerts.results);
        }
    };


    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    return (
        <div>
            <div className="flex items-center justify-between my-4">
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        className="input w-48 p-2 rounded-lg focus:ring-2 focus:ring-theme-1  text-black placeholder-gray-500"
                        placeholder="Rechercher par RCCM "
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                        onClick={handleSearchSubmit}
                    >
                        Rechercher
                    </button>
                </div>
            </div>

            <div className="flex mb-4">
                <button
                    className={`px-4 py-2 rounded-l ${filter === "actifs" ? "bg-orange-500 text-white" : "bg-gray-300 text-gray-700"
                        }`}
                    onClick={() => handleFilterChange("actifs")}
                >
                    Actifs
                </button>
                <button
                    className={`px-4 py-2 rounded-r ${filter === "inactifs" ? "bg-orange-500 text-white" : "bg-gray-300 text-gray-700"
                        }`}
                    onClick={() => handleFilterChange("inactifs")}
                >
                    Inactifs
                </button>
            </div>


            <div className="overflow-auto">
                {loading ? (
                    <Skeleton count={ELEMENT_PER_PAGE} />
                ) : filteredAlerts.length > 0 ? (
                    <>
                        <table className="table-auto w-full text-gray-700 table table-report sm:mt-2">
                            <thead>
                                <tr className=" bg-transparent text-black">
                                    <th className="p-2 text-left">Client</th>
                                    <th className="p-2 text-left">Critères</th>
                                    <th className="p-2 text-left">Entreprise</th>
                                    <th className="p-2 text-left">Statut</th>
                                    <th className="p-2 text-left">Notifications</th>
                                    <th className="p-2 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAlerts.map((alert, index) => (
                                    <tr key={index} className="bg-white">
                                        <td className="p-2">
                                            {alert.user.first_name} {alert.user.last_name} <br />
                                            <small className="text-gray-500">{alert.user.email}</small>
                                        </td>
                                        <td className="p-2">
                                            {alert.criteres.map((critere) => critere.name).join(", ")}
                                        </td>
                                        <td className="p-2">{alert.rccm_entreprise}</td>
                                        <td className={`p-2 ${alert.is_active ? "text-orange-500" : "text-gray-500"}`}>
                                            {alert.is_active ? "Actif" : "Inactif"}
                                        </td>
                                        <td className="p-2 text-center">{alert.notifications_count}</td>
                                        <td className="p-2">
                                            <button className="bg-gray-300 px-4 py-2 rounded text-sm hover:bg-gray-400">
                                                <Link to={`/notification/${alert.id}`}>Notifications</Link>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div
                            className={
                                alerts.count > ELEMENT_PER_PAGE
                                    ? "px-5 py-3 flex justify-end text-right border-t border-gray-200"
                                    : "hidden"
                            }
                        >
                            <button
                                onClick={() => handlePreviousPage(alerts.previous)}
                                type="button"
                                className="button flex w-36 items-center bg-orange-500 text-white-700"
                            >
                                <Icons.ArrowLeft />
                                <span> Précédent </span>
                            </button>
                            <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                                {page} / {totalPage}
                            </div>
                            <button
                                onClick={() => handleNextPage(alerts.next)}
                                type="button"
                                className="button flex w-36 items-center bg-orange-500 text-white-700"
                            >
                                <span> Suivant </span>
                                <Icons.ArrowRight />
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="bg-white p-5 text-center text-3xl text-gray-600 mt-3">
                        Aucune alerte trouvée.
                    </div>
                )}
            </div>
        </div>
    );
};

export default Alert;