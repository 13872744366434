import { useCallback, useEffect, useState } from "react";
import styleDate from "../utils/Date";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { Link } from "react-router-dom";
import { Utils } from "../utils";
import { nextPage, previousPage } from "../utils/pagination";

const Organisation = () => {
    const [allorganisations, setAllOrganisations] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const ELEMENET_PER_PAGE = 20;

    const fetchOrganisations = useCallback(async () => {
        setLoading(true);
        setIsSearching(false); // Réinitialise la recherche

        try {
            const response = await Api.get(`recouv/company/`);
            if (response) {
                setLoading(false);
            }
            setAllOrganisations(response);
            setOrganisations(response.results);

            const PAGE_NUMBER = Math.ceil(response.count / ELEMENET_PER_PAGE);
            setTotalPage(PAGE_NUMBER);
        } catch (error) {
            console.error("Error fetching organisations:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchOrganisations();
    }, [fetchOrganisations]);

    const handleNextPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await nextPage(params);
            setPage(prevPage => prevPage + 1);
            setAllOrganisations(datas);
            setOrganisations(datas.results);
        }
        setLoading(false);
    };

    const handlePreviousPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await previousPage(params);
            page > 0 ? setPage(page - 1) : setPage(1);
            setAllOrganisations(datas);
            setOrganisations(datas.results);
        }
        setLoading(false);
    };

    const handleSearch = async () => {
        if (searchTerm.trim() !== "") {
            setLoading(true);
            setIsSearching(true);

            try {
                const url = `https://api.datagreffe.ci/recouv/company/?name=${searchTerm}`;
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setOrganisations(data.results || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            } finally {
                setLoading(false);
            }
        } else {
            setOrganisations(allorganisations.results);
            setIsSearching(false);

        }
    };

    const toggleStatus = async (id, currentStatus) => {
        const url = currentStatus
            ? "https://api.datagreffe.ci/recouv/account/batch_deactivate_company_users/"
            : "https://api.datagreffe.ci/recouv/account/batch_activate_company_users/";

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify({ company_id: id }),
            });

            if (response.ok) {
                setOrganisations((prevOrgs) =>
                    prevOrgs.map((org) =>
                        org.id === id ? { ...org, is_active: !currentStatus } : org
                    )
                );
            } else {
                console.error("Erreur lors de la mise à jour du statut.");
                alert("Une erreur est survenue lors de l'activation/désactivation.");
            }
        } catch (error) {
            console.error("Erreur réseau :", error);
            alert("Impossible de mettre à jour le statut de cette organisation.");
        }
    };

    const toggleDropdown = (id) => {
        setOpenDropdown(openDropdown === id ? null : id);
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center my-4">
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        className="input w-48 p-2 text-black rounded-lg focus:ring-2 focus:ring-theme-1 placeholder-gray-500"
                        placeholder="Rechercher par Denomination"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                        onClick={handleSearch}
                    >
                        Rechercher
                    </button>
                </div>
                <button className="bg-orange-500 text-white px-4 py-2 rounded">
                    <Link to={"/Creation-Organisation"}>Nouvelle organisation</Link>
                </button>
            </div>
            <div className="overflow-auto mb-10">
                <table className="table-auto w-full text-gray-700 table table-report sm:mt-2">
                    <thead>
                        <tr className=" bg-transparent text-black">
                            <th>Denomination</th>
                            <th>Adresse</th>
                            <th>Solde</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Statut</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="4">
                                    <Skeleton count={5} />
                                </td>
                            </tr>
                        ) : (
                            organisations.map((org) => (
                                <tr key={org.id} className="bg-white rounded-lg">
                                    <td>{org?.name || "N/A"}</td>
                                    <td>{org?.site || "N/A"}</td>
                                    <td>{org?.solde || "N/A"} Crédits</td>
                                    <td>{org?.contact || "N/A"}</td>
                                    <td>{org?.email || "N/A"}</td>
                                    <td
                                        className={org?.is_active === "true" || org?.is_active === true ? "text-green-500" : "text-gray-500"}
                                    >
                                        {org?.is_active === "true" || org?.is_active === true ? "Actif" : "Inactif"}
                                    </td>

                                    <td>
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => toggleStatus(org.id, org?.is_active)}
                                                className="bg-gray-500 text-white px-2 py-1 rounded flex items-center"
                                            >
                                                {org?.is_active ? "Désactiver" : "Activer"}
                                            </button>
                                            <div className="relative inline-block">
                                                <button
                                                    onClick={() => Utils.Dom.toggleVisibility(`#demande-action-dropdown-${org.id}`)}
                                                    className="bg-gray-500 text-white px-2 py-1 rounded flex items-center"
                                                >
                                                    <Icons.Eye className="mr-1" />
                                                    Voir
                                                </button>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <div
                                                className="dropdown-box w-40"
                                                id={`demande-action-dropdown-${org.id}`}
                                                data-popper-placement="bottom-end"
                                            >
                                                <div className="dropdown-box__content box dark:bg-dark-1 p-2">
                                                    <Link to={`/Historique-Organisation/${org.id}`} className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                                        Historique Recherches
                                                    </Link>
                                                    <Link to={`/Liste-Utilisateur/${org.id}`} className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                                        Voir Collaborateurs
                                                    </Link>
                                                    {/* <Link to={"/"} className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                                        Clé Api
                                                    </Link> */}
                                                    <Link to={`/Parametre-Facturation/${org.id}`} className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                                        Parametres de Facturation
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <div
                    className={
                        !isSearching & allorganisations.count > 20
                            ? "px-5 py-3 flex justify-end text-right border-t border-gray-200"
                            : "hidden"
                    }
                >
                    <button
                        onClick={() => handlePreviousPage(allorganisations.previous)}
                        type="button"
                        className="button flex w-36 items-center border bg-theme-1 text-white"
                    >
                        <span>
                            <Icons.ArrowLeft />
                        </span>
                        <span> Précédent </span>
                    </button>
                    <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                        {organisations
                            ? (
                                <span>
                                    {page} / {totalPage}
                                </span>
                            ) : (
                                0
                            )}
                    </div>
                    <button
                        onClick={() => handleNextPage(allorganisations.next)}
                        type="button"
                        className="button flex w-36 items-center border bg-theme-1 text-white"
                    >
                        <span> Suivant </span>
                        <span>
                            <Icons.ArrowRight />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Organisation;
