import { useCallback, useEffect, useState } from "react";
import { Api } from "../services/Api";
import { Utils } from "../utils";
import Select from 'react-select';
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";



const AchatOrganisation = () => {
    const [achats, setAchats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredAchats, setFilteredAchats] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [types, setTypes] = useState([]);
    const [formData, setFormData] = useState({ client: "", type: "" });
    const [page, setPage] = useState(1);
    const [organisationsLoading, setOrganisationsLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const ELEMENT_PER_PAGE = 10;

    const fetchAchats = useCallback(async () => {
        setLoading(true);
        try {
            const response = await Api.get(`recouv/subscription/company_subscriptions/`);
            setAchats(response || []);
            const PAGE_NUMBER = Math.ceil((response.count || 1) / ELEMENT_PER_PAGE);
            setTotalPage(PAGE_NUMBER);
            setFilteredAchats(response);
        } catch (error) {
            console.error("Error fetching achats:", error);
        }
        setLoading(false);
    }, [page]);

    // const fetchModalData = useCallback(async () => {
    //     try {
    //         const [clientsResponse, typesResponse] = await Promise.all([
    //             Api.get("recouv/company/"),
    //             Api.get("recouv/pack/"),
    //         ]);
    //         setClients(clientsResponse.results || []);
    //         setTypes(typesResponse || []);
    //     } catch (error) {
    //         console.error("Error fetching modal data:", error);
    //     }
    // }, []);

    const fetchAllClients = async () => {
        setOrganisationsLoading(true);
        try {
            let allOrganisations = [];
            let nextUrl = "recouv/company/";
            let hasMore = true;

            while (hasMore) {
                const response = await Api.get(nextUrl);
                if (response && response.results) {
                    allOrganisations = [...allOrganisations, ...response.results];

                    if (response.next) {
                        const url = new URL(response.next);
                        nextUrl = url.pathname.replace(/^\//, '') + url.search;
                    } else {
                        hasMore = false;
                    }
                } else {
                    hasMore = false;
                }
            }

            setClients(allOrganisations);
        } catch (error) {
            // console.error("Error fetching all organisations:", error);
            toast.error("Erreur lors du chargement des Organisation");
        }
        setOrganisationsLoading(false);
    };

    const fetchModalData = useCallback(async () => {
        try {
            const typesResponse = await Api.get("recouv/pack/");
            setTypes(typesResponse || []);
            fetchAllClients();
        } catch (error) {
            console.error("Error fetching modal data:", error);
        }
    }, []);

    const handleModalOpen = () => {
        setIsModalOpen(true);
        fetchModalData();
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setFormData({ client: "", type: "" });
    };

    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query) {
            try {
                const url = `https://api.datagreffe.ci/recouv/subscription/?reference=${query}`;
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }
                const data = await response.json();
                setFilteredAchats(data || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setFilteredAchats(achats);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchQuery) {
            try {
                const url = `https://api.datagreffe.ci/recouv/subscription/?reference=${searchQuery}`;
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setFilteredAchats(data || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setFilteredAchats(achats);
        }
    };

    const handleFormSubmit = async () => {
        const res = await Api.get(`recouv/paymentmethod/`);
        const payid = res.results[1].id;

        const payload = {
            company_id: formData.client,
            credit: formData.credits,
        };

        console.log("Payload:", payload);

        try {
            const response = await fetch(`https://api.datagreffe.ci/recouv/account/credit_company_account/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'enregistrement de l'achat");
            }

            const result = await response.json();
            console.log("Achat enregistré :", result);
            handleModalClose();
            fetchAchats();
            toast.success("Achat effectuer avec avec succès!")
        } catch (error) {
            console.error("Erreur lors de l'enregistrement de l'achat :", error);
            toast.error(error)

        }
    };




    useEffect(() => {
        fetchAchats();
    }, [fetchAchats]);

    return (
        <div>
            {/* Search Bar */}
            <div className="flex items-center justify-between my-4">
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        className="input w-48 p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-black placeholder-gray-500"
                        placeholder="Rechercher par Reference"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                        onClick={handleSearchSubmit}
                    >
                        Rechercher
                    </button>
                </div>

                {/* Add Purchase Button */}
                <div className="flex items-center gap-2">
                    <button
                        onClick={handleModalOpen}
                        className="bg-orange-500 text-white px-6 py-3 rounded-full hover:bg-orange-600"
                    >
                        Enregistrer un achat
                    </button>
                </div>
            </div>

            {/* Purchases Table */}
            <div className="overflow-auto mb-10">
                {loading ? (
                    <Skeleton count={5} />
                ) : filteredAchats && filteredAchats.length > 0 ? (
                    <>
                        <table className="table-auto w-full text-gray-700 table table-report sm:mt-2">
                            <thead>
                                <tr className="bg-transparent text-black">
                                    <th className="p-2 text-left">Référence</th>
                                    <th className="p-2 text-left">Organisation</th>
                                    <th className="p-2 text-left">Date d'achat</th>
                                    <th className="p-2 text-left">Date d'expiration</th>
                                    <th className="p-2 text-left">Moyen de paiement</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAchats.map((achat, index) => (
                                    <tr key={index} className="bg-white rounded-lg">
                                        <td className="p-2">{achat?.reference || "N/A"}</td>
                                        <td className="p-2 font-semibold">{achat.company?.name || "N/A"}</td>
                                        <td className="p-2">{achat?.created_at || "N/A"}</td>
                                        <td className="p-2">{achat?.end_date || "N/A"}</td>
                                        <td className="p-2">
                                            {achat?.payment?.payment_method?.name || "N/A"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="bg-white p-5 text-center text-3xl text-gray-600 mt-3">
                        Aucun achat trouvé.
                    </div>
                )}

                {/* Pagination */}
                <div className="px-5 py-3 flex justify-end text-right border-t border-gray-200">
                    <button
                        onClick={() => page > 1 && setPage(page - 1)}
                        disabled={page === 1}
                        className={`button flex w-36 items-center border ${page === 1 ? "bg-gray-300 text-gray-600" : "bg-theme-1 text-white"}`}
                    >
                        <Icons.ArrowLeft />
                        <span> Précédent </span>
                    </button>
                    <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                        {page} / {totalPage}
                    </div>
                    <button
                        onClick={() => page < totalPage && setPage(page + 1)}
                        disabled={page === totalPage}
                        className={`button flex w-36 items-center border ${page === totalPage ? "bg-gray-300 text-gray-600" : "bg-theme-1 text-white"}`}
                    >
                        <span> Suivant </span>
                        <Icons.ArrowRight />
                    </button>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg w-full sm:w-4/5 lg:w-1/2">
                        <div className="text-2xl font-semibold mb-6 text-center text-orange-600">ENREGISTREMENT D'UN ACHAT</div>
                        <div className="mb-6">
                            <label className="block text-lg font-medium text-gray-700 mb-2">Sélectionnez une organisation</label>
                            <Select
                                options={clients.map(client => ({
                                    value: client.id,
                                    label: `${client?.name}`
                                }))}
                                onChange={selectedOption => setFormData({ ...formData, client: selectedOption.value })}
                                placeholder="Sélectionnez un client"
                                isSearchable
                                isLoading={organisationsLoading}
                                loadingMessage={() => "Chargement des clients..."}
                                noOptionsMessage={() => organisationsLoading ? "Chargement..." : "Aucun client trouvé"}
                                className="w-full"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: '#e5e7eb',
                                        borderRadius: '0.375rem',
                                        padding: '0.5rem',
                                        backgroundColor: '#ffffff',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
                                        '&:hover': { borderColor: '#FB923C' },
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                            ? '#FB923C'
                                            : state.isFocused
                                                ? '#FFEDD5'
                                                : 'transparent',
                                        color: state.isSelected
                                            ? '#ffffff'
                                            : state.isFocused
                                                ? '#FB923C'
                                                : '#374151',
                                        padding: '10px 15px',
                                        fontSize: '1rem',
                                        transition: 'background-color 0.2s ease, color 0.2s ease',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: '#374151',
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: '#6B7280',
                                    }),
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block text-lg font-medium text-gray-700 mb-2">Nombre de crédits</label>
                            <input
                                type="number"
                                min="1"
                                className="w-full p-4 border-2 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500"
                                value={formData.credits}
                                onChange={(e) => setFormData({ ...formData, credits: e.target.value })}
                                placeholder="Entrez le nombre de crédits"
                            />
                        </div>

                        <div className="flex gap-4 mt-8 justify-end">
                            <button
                                onClick={handleModalClose}
                                className="px-6 py-3 rounded-lg bg-gray-300 hover:bg-gray-400 text-gray-800 text-lg"
                            >
                                Fermer
                            </button>
                            <button
                                onClick={handleFormSubmit}
                                className="px-6 py-3 rounded-lg bg-theme-1 hover:bg-orange-500 text-white text-lg"
                            >
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default AchatOrganisation;