import { Utils } from "../utils";
import { Auth } from "../utils/Auth";

// const HOST = "http://127.0.0.1:5000";
// const PORT = "8000";
const URL = process.env.REACT_APP_HOST;
// ?? `${HOST}:${PORT}`;
//console.log("URL", URL);
// const endpoint = {
//   history: "recouv/history/",
//   document: "recouv/datadocumentuser/",
// };
const HEADERS = new Headers({
  "Content-type": "application/json",
  Accept: "application/json",
  Connection: "keep-alive",
  Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
});

// const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

// const HEADERSOFFLINE = new Headers({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
//   Authorization: `Bearer ${dataUser?.token}`,
// });

const get = (endpoint, signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${endpoint}`, {
      headers: HEADERS,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
        // window.location.replace("/")
      })
      .catch((error) => reject(error));
  });
};

const post = (endpoint, payload = "", signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${endpoint}`, {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(payload),  // Sérialiser le payload en JSON
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};



const put = (endpoint, payload = "", signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${endpoint}`, {
      method: "put",
      headers: HEADERS,
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

// const erase = (endpoint, signal = new AbortController().signal) => {
//   return new Promise((resolve, reject) => {
//     fetch(`${URL}/${endpoint}`, {
//       method: "delete",
//       headers: HEADERS,
//       signal,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           return reject({
//             status: response.status,
//             messages: getResponseErrors(response),
//           });
//         }

//         return response.json();
//       })
//       .then((result) => {
//         resolve(result);
//       })
//       .catch((error) => reject(error));
//   });
// };

const getResponseErrors = (response) => {
  return new Promise((resolve, reject) => {
    if (!response) reject(null);
    if (response.status === 401) {
      Auth.removeSessionToken();
      // window.location.replace('/connexion');
    }

    response.json().then((result) => {
      let errorMessages = [];

      errorMessages.push(result.message);

      for (let field in result) errorMessages.push(`${field} ${result[field]}`);

      resolve(errorMessages);
    });
  });
};

export const Api = {
  get,
  post,
  put,
  // erase,
};
