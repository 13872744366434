import { Link, NavLink, useLocation } from 'react-router-dom';
import logoImg from '../assets/images/Logo_DATA_GREFFE.png';
import * as Icons from 'react-feather';
import { Utils } from '../utils';
import { Auth } from '../utils/Auth';

import { useState } from "react";

export function MobileMenu() {
    const { pathname } = useLocation();
    const { role } = Auth.getUser();
    const activeClassName = "menu--active";
    const dynamicClassName = ({ isActive }) =>
        isActive ? `menu ${activeClassName}` : "menu";

    const [showClientDropdown, setShowClientDropdown] = useState(false);
    const [showAchatDropdown, setShowAchatDropdown] = useState(false);

    return (
        <div className="mobile-menu md:hidden">
            <div className="mobile-menu-bar pt-5">
                <Link to="/" className="flex mr-auto">
                    <img alt="Logo" className="w-16" src={logoImg} />
                </Link>
                <button
                    className="btn btn-link rounded"
                    id="mobile-menu-toggler"
                    onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
                >
                    <Icons.BarChart2 className="w-8 h-8 text-white transform -rotate-90" />
                </button>
            </div>
            <ul className="border-t bg-theme-11 py-5 hidden" id="mobile-menu-dropdown">
                <li>
                    <NavLink to="/Alert" className={dynamicClassName}>
                        <div className="menu__icon">
                            <Icons.Volume2 />
                        </div>
                        <div className="menu__title"> Alert </div>
                    </NavLink>
                </li>

                <li className={role === "administrateur_TCA" ? "hidden" : ""}>
                    <div>
                        <button
                            className="menu w-full flex justify-between items-center"
                            onClick={() => setShowClientDropdown(!showClientDropdown)}
                        >
                            <div className="flex items-center">
                                <div className="menu__icon">
                                    <Icons.Search />
                                </div>
                                <div className="menu__title"> Client </div>
                            </div>
                            <Icons.ChevronDown
                                className={`transition-transform ${showClientDropdown ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        {showClientDropdown && (
                            <div className="ml-5">
                                <NavLink
                                    to="/Organisation"
                                    className={`menu ${pathname === "/Organisation" ? activeClassName : ""}`}
                                    onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
                                >
                                    <div className="menu__icon">
                                        <Icons.Globe />
                                    </div>
                                    <div className="menu__title"> Organisation </div>
                                </NavLink>
                                <NavLink
                                    to="/Client"
                                    className={`menu ${pathname === "/Client" ? activeClassName : ""}`}
                                    onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
                                >
                                    <div className="menu__icon">
                                        <Icons.Users />
                                    </div>
                                    <div className="menu__title"> Clients </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                </li>

                <li className={role === "administrateur_TCA" ? "hidden" : ""}>
                    <div>
                        <button
                            className="menu w-full flex justify-between items-center"
                            onClick={() => setShowAchatDropdown(!showAchatDropdown)}
                        >
                            <div className="flex items-center">
                                <div className="menu__icon">
                                    <Icons.ShoppingCart />
                                </div>
                                <div className="menu__title"> Achats </div>
                            </div>
                            <Icons.ChevronDown
                                className={`transition-transform ${showAchatDropdown ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        {showAchatDropdown && (
                            <div className="ml-5">
                                <NavLink
                                    to="/Achat-Utilisateurs"
                                    className={`menu ${pathname === "/Achat-Utilisateurs" ? activeClassName : ""
                                        }`}
                                    onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
                                >
                                    <div className="menu__icon">
                                        <Icons.Globe />
                                    </div>
                                    <div className="menu__title"> Achat Utilisateur </div>
                                </NavLink>
                                <NavLink
                                    to="/Achat-Organisation"
                                    className={`menu ${pathname === "/Achat-Organisation" ? activeClassName : ""
                                        }`}
                                    onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
                                >
                                    <div className="menu__icon">
                                        <Icons.Globe />
                                    </div>
                                    <div className="menu__title"> Achat Organisation </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                </li>

                <li>
                    <NavLink to="/Document-Acheter" className={dynamicClassName}>
                        <div className="menu__icon">
                            <Icons.Folder />
                        </div>
                        <div className="menu__title"> Document Acheter </div>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}