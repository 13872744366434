import { useCallback, useEffect, useMemo, useState } from "react";
import styleDate from "../utils/Date";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import { nextPage, previousPage } from "../utils/pagination";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import Select from 'react-select';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ListUtilisateur = () => {
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    const urlPath = window.location.pathname;
    const id = urlPath.split("/").filter(Boolean).pop();

    const fetchUtilisateurs = async () => {
        setLoading(true);
        try {
            const response = await Api.get(`recouv/users/?company=${id}`);
            setUtilisateurs(response.results);
        } catch (error) {
            console.error("Error fetching utilisateurs:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = async (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);

        if (searchTerm.trim() !== "") {
            try {
                const url = `https://api.datagreffe.ci/recouv/users/?company=${id}&name=${searchTerm}`;
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setUtilisateurs(data.results || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setUtilisateurs(utilisateurs);
        }
    };

    const handleSearch = async () => {
        if (searchTerm.trim() !== "") {
            try {
                const url = `https://api.datagreffe.ci/recouv/users/?company=${id}&name=${searchTerm}`;
                const response = await fetch(url, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }

                const data = await response.json();
                setUtilisateurs(data.results || []);
            } catch (error) {
                console.error("Erreur lors de la recherche :", error);
            }
        } else {
            setUtilisateurs(utilisateurs);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet collaborateur ?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(
                `https://api.datagreffe.ci/recouv/users/${id}/`,
                {
                    method: 'DELETE',
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                }
            );

            if (response.status === 204) {
                toast.success("Utilisateur supprimé avec succès !");
                setUtilisateurs(utilisateurs.filter((user) => user.id !== id));
            } else {
                toast.error("Une erreur s'est produite lors de la suppression.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de l'utilisateur :", error);
            toast.error("Impossible de supprimer cet utilisateur.");
        }
    };

    const toggleStatus = async (id, companyId) => {
        const utilisateur = utilisateurs.find((user) => user.id === id);
        if (!utilisateur) {
            toast.warn("Utilisateur introuvable.");
            return;
        }

        const newStatus = !utilisateur.is_company_activate;
        const url = newStatus
            ? "https://api.datagreffe.ci/recouv/account/activate/"
            : "https://api.datagreffe.ci/recouv/account/deactivate/";

        const payload = newStatus
            ? { user_id: id, company_id: companyId }
            : { user_id: id };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {

                toast.success(`Utilisateur ${newStatus ? "activé" : "désactivé"} avec succès !`);
                setUtilisateurs((prevUtilisateurs) =>
                    prevUtilisateurs.map((user) =>
                        user.id === id ? { ...user, is_company_activate: newStatus } : user
                    )
                );
            } else {
                toast.error("Une erreur s'est produite lors de la mise à jour du statut.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut de l'utilisateur :", error);
            toast.error("Impossible de mettre à jour le statut de cet utilisateur.");
        }
    };


    useEffect(() => {
        fetchUtilisateurs();
    }, []);

    return (
        <div className="p-4">
            <div className="flex justify-between items-center my-4">
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        className="input w-48 p-2 text-black rounded-lg focus:ring-2 focus:ring-theme-1 placeholder-gray-500"
                        placeholder="Rechercher par email"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <button
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-700"
                        onClick={handleSearch}
                    >
                        Rechercher
                    </button>
                </div>
                <button className="bg-orange-500 text-white px-4 py-2 rounded">
                    <Link to={`/Creation-Utilisateur/${id}`}>Nouveau Collaborateur</Link>
                </button>
            </div>
            <div className="overflow-auto mb-10">
                <table className="table-auto w-full text-gray-700 table table-report sm:mt-2">
                    <thead>
                        <tr className="bg-transparent text-black">
                            <th>Nom & Prénoms</th>
                            <th>Email</th>
                            <th>Rôle</th>
                            <th>Numéro de téléphone</th>
                            <th>Statut</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="6">
                                    <Skeleton count={5} />
                                </td>
                            </tr>
                        ) : utilisateurs.length > 0 ? (
                            utilisateurs.map((utilisateur) => (
                                <tr key={utilisateur.id} className="bg-white rounded-lg">
                                    <td>{utilisateur?.first_name || "N/A"} {utilisateur?.last_name || "N/A"}</td>
                                    <td>{utilisateur?.email || "N/A"}</td>
                                    <td>{utilisateur?.role || "N/A"}</td>
                                    <td>{utilisateur?.phone || "N/A"}</td>
                                    <td
                                        className={utilisateur?.is_company_activate === "true" || utilisateur?.is_company_activate === true ? "text-green-500" : "text-gray-500"}
                                    >
                                        {utilisateur?.is_company_activate === "true" || utilisateur?.is_company_activate === true ? "Actif" : "Inactif"}
                                    </td>
                                    <td>
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => toggleStatus(utilisateur.id, id)}
                                                className="bg-gray-500 text-white px-2 py-1 rounded flex items-center"
                                            >
                                                {utilisateur?.is_company_activate ? "Désactiver" : "Activer"}
                                            </button>
                                            <div className="relative inline-block">
                                                <button
                                                    onClick={() => Utils.Dom.toggleVisibility(`#demande-action-dropdown-${utilisateur.id}`)}
                                                    className="bg-gray-500 text-white px-2 py-1 rounded flex items-center"
                                                >
                                                    <Icons.Eye className="mr-1" />
                                                    Voir
                                                </button>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <div
                                                className="dropdown-box w-40"
                                                id={`demande-action-dropdown-${utilisateur.id}`}
                                                data-popper-placement="bottom-end"
                                            >
                                                <div className="dropdown-box__content box dark:bg-dark-1 p-2">
                                                    <Link to={`/Edition-Utilisateur/${utilisateur.id}`} className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                                        Editer
                                                    </Link>
                                                    <button
                                                        onClick={() => handleDelete(utilisateur.id)}
                                                        className="mr-2 bg-gray-500 text-white px-2 py-1 rounded hover:bg-orange-700"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">Aucun utilisateur trouvé</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ListUtilisateur;