import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
// import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { nextPage, previousPage } from "../utils/pagination";
import { useParams } from "react-router-dom";

const Notifications = () => {
    const urlPath = window.location.pathname;
    const id = urlPath.split("/").filter(Boolean).pop();
    const [dataNotifications, setDataNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const ELEMENT_PER_PAGE = 20;

    const listNotifications = useCallback(async () => {
        try {
            setLoading(true);
            const results = await Api.get(`notifications/notifications/?alert=${id}`);

            if (results) {
                setDataNotifications(results.results);
                const PAGE_NUMBER = Math.ceil(results.count / ELEMENT_PER_PAGE);
                setTotalPage(PAGE_NUMBER);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
            alert("Une erreur est survenue lors du chargement des notifications.");
        } finally {
            setLoading(false);
        }
    }, [id]);

    const handleNextPage = async (params) => {
        setLoading(true);
        try {
            if (params) {
                const datas = await nextPage(params);
                setPage(page + 1);
                setDataNotifications(datas.results);
            }
        } catch (error) {
            console.error("Error fetching next page:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousPage = async (params) => {
        setLoading(true);
        try {
            if (params) {
                const datas = await previousPage(params);
                page > 1 ? setPage(page - 1) : setPage(1);
                setDataNotifications(datas.results);
            }
        } catch (error) {
            console.error("Error fetching previous page:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        listNotifications();
    }, [listNotifications]);

    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-6">
                <div className="intro-y">
                    <div className="overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                        {loading ? (
                            <Skeleton count={20} />
                        ) : dataNotifications.length > 0 ? (
                            <>
                                <table className="table table-report sm:mt-2">
                                    <thead className="text-gray-800">
                                        <tr className="bg-transparent text-black">
                                            <th className="whitespace-no-wrap">Entreprise</th>
                                            <th className="whitespace-no-wrap">Critères</th>
                                            <th className="text-center whitespace-no-wrap">Notifications</th>
                                            <th className="text-center whitespace-no-wrap">Statut</th>
                                            <th className="text-center whitespace-no-wrap">Canal</th>
                                            <th className="text-center whitespace-no-wrap">Date d'envoi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataNotifications.map((item, index) => (
                                            <tr className="intro-x" key={index}>
                                                <td className="text-start">
                                                    <div className="text-gray-800">{item.alert.rccm_entreprise}</div>
                                                </td>
                                                <td className="text-start">
                                                    <div className="text-gray-800">{item.alert.criteres[0]?.name}</div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="text-gray-800">{item.title}</div>
                                                </td>
                                                <td className="text-center">
                                                    <div
                                                        className={`text-white p-1 rounded ${item.alert.is_active
                                                            ? "bg-green-500"
                                                            : "bg-red-500"
                                                            }`}
                                                    >
                                                        {item.alert.is_active ? "envoyé" : "non envoyé"}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="text-gray-800">{item.alert.canal}</div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="text-gray-800">{styleDate(item.created_at)}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div
                                    className={
                                        dataNotifications.length > 20
                                            ? "px-5 py-3 flex justify-end text-right border-t border-gray-200"
                                            : "hidden"
                                    }
                                >
                                    <button
                                        onClick={() => handlePreviousPage(dataNotifications.previous)}
                                        type="button"
                                        className="button flex w-36 items-center border bg-theme-1 text-white"
                                    >
                                        <Icons.ArrowLeft />
                                        <span> Précédent </span>
                                    </button>
                                    <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                                        {dataNotifications.length ? (
                                            <span>
                                                {page} / {totalPage}
                                            </span>
                                        ) : (
                                            0
                                        )}
                                    </div>
                                    <button
                                        onClick={() => handleNextPage(dataNotifications.next)}
                                        type="button"
                                        className="button flex w-36 items-center border bg-theme-1 text-white"
                                    >
                                        <span> Suivant </span>
                                        <Icons.ArrowRight />
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="intro-y bg-white p-5 text-center text-3xl text-gray-600 mt-3">
                                Aucune notification trouvée.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;