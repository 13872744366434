import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
// import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import { nextPage, previousPage } from "../utils/pagination";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import Select from 'react-select';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



const CreationUtilisateur = () => {
    const [nomPrenom, setNomPrenom] = useState("");
    const [numeroTelephone, setNumeroTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [isStatus, setIsStatus] = useState(true);
    const [address, setAddress] = useState("");
    const [companyName, setCompanyName] = useState(""); // Champ non modifiable
    const [role, setRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState([]); // Données des entreprises
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState("");
    const [passwordShown2, setPasswordShown2] = useState(false);




    const location = useLocation();
    const navigate = useNavigate();  // Initialisation de useNavigate


    const idFromUrl = location.pathname.split("/").pop(); // Récupère l'ID de l'URL


    const checkPasswordStrength = (password) => {
        if (password.length < 6) {
            setPasswordStrength("Faible");
        } else if (password.length < 10) {
            setPasswordStrength("Moyen");
        } else {
            setPasswordStrength("Fort");
        }
    };


    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    useEffect(() => {
        // Récupération des rôles
        const fetchRoles = async () => {
            try {
                const response = await Api.get("recouv/roles/");
                let filteredRoles = response.results
                    .map((r) => ({
                        id: r.id,
                        name: r.name,
                    }))
                    .sort((a, b) => a.id - b.id)
                    .filter((r) => r.name.toLowerCase() !== "admin" || r.name === role);
                setRoles(filteredRoles);
            } catch (error) {
                console.error("Erreur lors de la récupération des rôles:", error);
            }
        };

        fetchRoles();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            company: idFromUrl,
            first_name: firstName,
            last_name: lastName,
            is_active: isActive,
            is_status: isStatus,
            phone: numeroTelephone,
            username,
            email,
            address,
            role: role ? [role.value] : [],
            password,
        };


        try {
            const response = await fetch("https://api.datagreffe.ci/recouv/users/", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Erreur lors de la création de l'Collaborateur: ${errorData.detail || 'Erreur inconnue'}`);
            } else {
                toast.success("Collaborateur créé avec succès!");

            }

            const result = await response.json();
            toast.success("Collaborateur créé avec succès!");
            navigate(-1);

        } catch (error) {
            console.error("Erreur:", error);
            toast.error("Erreur lors de la création de l'Collaborateur.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <form>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-black">
                            Nom d'utilisateur
                        </label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Prénom</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Nom</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4 relative">
                        <label className="block text-sm font-medium text-black">Mot de passe</label>
                        <div className="relative">
                            <input
                                type={passwordShown ? "text" : "password"}
                                className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800 pr-12"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div
                                onClick={togglePassword}
                                className="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 cursor-pointer"
                            >
                                {passwordShown ? <Icons.Eye /> : <Icons.EyeOff />}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-black">Numéro de téléphone</label>
                        <PhoneInput
                            country={"ci"} // Définit la Côte d'Ivoire par défaut
                            value={numeroTelephone}
                            onChange={setNumeroTelephone}
                            inputClass="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-2 text-black"
                            containerClass="w-full"
                            inputStyle={{ width: "100%" }}
                            enableSearch={true} // Permet la recherche de pays
                            dropdownStyle={{ color: "black", backgroundColor: "white" }} // Texte de la liste en noir
                            searchStyle={{ color: "black" }} // Texte de la recherche en noir
                            enableLongNumbers={true}

                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">
                            Adresse email
                        </label>
                        <input
                            type="email"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Adresse</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Rôle</label>
                        <Select
                            options={roles.map((role) => ({
                                value: role.id,
                                label: role.name,
                            }))}
                            value={role}
                            onChange={setRole}
                            placeholder="Sélectionnez un rôle"
                            isClearable
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderColor: '#e5e7eb',
                                    borderRadius: '0.375rem',
                                    padding: '0.1rem',
                                    backgroundColor: '#ffffff',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
                                    '&:hover': { borderColor: '#FB923C' },
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? '#FB923C'
                                        : state.isFocused
                                            ? '#FFEDD5'
                                            : 'transparent',
                                    color: state.isSelected
                                        ? '#ffffff'
                                        : state.isFocused
                                            ? '#FB923C'
                                            : '#374151',
                                    padding: '10px 15px',
                                    fontSize: '1rem',
                                    transition: 'background-color 0.2s ease, color 0.2s ease',
                                    cursor: 'pointer',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#374151',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#6B7280',
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-700"
                        disabled={loading}
                    >
                        {loading ? <Skeleton width={100} /> : "Créer le Collaborateur"}
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default CreationUtilisateur;