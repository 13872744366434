import { useCallback, useEffect, useState } from "react";
import { Api } from "../services/Api";
import { Utils } from "../utils";
import Select from 'react-select';
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditOrganisationCollab = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [numeroTelephone, setNumeroTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState(null);
    const [address, setAddress] = useState(null);
    const [isStatus, setIsStatus] = useState(false);
    const [company, setCompany] = useState({ name: "", id: "" });
    const [role, setRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();  // Initialisation de useNavigate
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const Id = location.pathname.split("/").pop();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const userResponse = await Api.get(`recouv/users/${Id}/`);
                const {
                    first_name,
                    last_name,
                    phone,
                    email,
                    role,
                    company,
                    username,
                    address,
                    is_status,
                } = userResponse;

                setFirstName(first_name || "");
                setLastName(last_name || "");
                setNumeroTelephone(phone || "");
                setEmail(email || "");
                setUsername(username || null);
                setAddress(address || null);
                setIsStatus(is_status || false);
                setCompany({ name: company.name, id: company.id });
                // Fetch roles for the dropdown
                const rolesResponse = await Api.get("recouv/roles/");
                let filteredRoles = rolesResponse.results
                    .map((r) => ({
                        id: r.id,
                        name: r.name,
                    }))
                    .sort((a, b) => a.id - b.id)
                    .filter((r) => r.name.toLowerCase() !== "admin" || r.name === role);
                setRoles(filteredRoles);
                const selectedRole = rolesResponse.results.find(r => r.name === role);
                setRole(selectedRole ? { value: selectedRole.id, label: selectedRole.name } : null);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [Id]);
    ;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            company: company.id,
            last_login: null,
            first_name: firstName,
            last_name: lastName,
            is_status: isStatus,
            phone: numeroTelephone,
            username,
            email,
            address,
            role: role ? [role.value] : [],
        };

        try {
            const response = await fetch(`https://api.datagreffe.ci/recouv/users/${Id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Erreur lors de la mise à jour de l'utilisateur: ${errorData.detail || 'Erreur inconnue'}`);
            }
            const result = await response.json();
            console.log("Utilisateur mis à jour avec succès:", result);
            navigate(-1);
            toast.success("Utilisateur mis à jour avec succès!");


        } catch (error) {
            console.error("Erreur:", error);
            toast.error(`Erreur: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-black">Prénom</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Nom</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-black">Numéro de téléphone</label>
                        <PhoneInput
                            country={"ci"}
                            value={numeroTelephone}
                            onChange={(e) => setNumeroTelephone(e.target.value)}
                            inputClass="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-2 text-black"
                            containerClass="w-full"
                            inputStyle={{ width: "100%" }}
                            enableSearch={true}
                            dropdownStyle={{ color: "black", backgroundColor: "white" }}
                            searchStyle={{ color: "black" }}
                            enableLongNumbers={true}

                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Adresse email</label>
                        <input
                            type="email"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Rôle</label>
                        <Select
                            options={roles.map((role) => ({
                                value: role.id,
                                label: role.name,
                            }))}
                            value={role}
                            onChange={setRole}
                            placeholder="Sélectionnez un rôle"
                            isClearable
                            required
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderColor: '#e5e7eb',
                                    borderRadius: '0.375rem',
                                    padding: '0.1rem',
                                    backgroundColor: '#ffffff',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
                                    '&:hover': { borderColor: '#FB923C' },
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? '#FB923C'
                                        : state.isFocused
                                            ? '#FFEDD5'
                                            : 'transparent',
                                    color: state.isSelected
                                        ? '#ffffff'
                                        : state.isFocused
                                            ? '#FB923C'
                                            : '#374151',
                                    padding: '10px 15px',
                                    fontSize: '1rem',
                                    transition: 'background-color 0.2s ease, color 0.2s ease',
                                    cursor: 'pointer',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#374151',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#6B7280',
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-700"
                        disabled={loading}
                    >
                        {loading ? <Skeleton width={100} /> : "Mettre à jour l'utilisateur"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditOrganisationCollab;