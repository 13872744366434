import { useCallback, useEffect, useMemo, useState } from "react";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { nextPage, previousPage } from "../utils/pagination";
import * as Icons from "react-feather";

const CrediterOrganisation = () => {
    const [montant, setMontant] = useState("");
    const [organisationId, setOrganisationId] = useState("");
    const [nomOrganisation, setNomOrganisation] = useState("");
    const [rccm, setRccm] = useState("");
    const [adresse, setAdresse] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const data = {
                montant,
                organisationId,
                nomOrganisation,
                rccm,
                adresse,
            };

            const response = await Api.post("/organisations/crediter", data);
            setMessage(`Crédit de ${montant} effectué avec succès pour l'organisation ${nomOrganisation} (ID: ${organisationId})`);
            console.log("Organisation créditée avec succès:", response.data);

        } catch (error) {
            setMessage("Erreur lors du crédit de l'organisation.");
            console.error("Erreur lors du crédit de l'organisation:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-black">Montant à créditer</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={montant}
                            onChange={(e) => setMontant(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">ID de l'Organisation</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={organisationId}
                            onChange={(e) => setOrganisationId(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Nom de l'Organisation</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={nomOrganisation}
                            onChange={(e) => setNomOrganisation(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">RCCM</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={rccm}
                            onChange={(e) => setRccm(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Adresse de l'Organisation</label>
                        <input
                            type="text"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={adresse}
                            onChange={(e) => setAdresse(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-700"
                        disabled={loading}
                    >
                        {loading ? <Skeleton width={100} /> : "Créditer l'organisation"}
                    </button>
                </div>
            </form>

            {message && (
                <div className="mt-4 text-center text-green-500">
                    {message}
                </div>
            )}
        </div>
    );
};

export default CrediterOrganisation;