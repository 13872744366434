import { useCallback, useEffect, useMemo, useState } from "react";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { nextPage, previousPage } from "../utils/pagination";
import * as Icons from "react-feather";

const HistoriqueOrganisation = () => {
    const abortController = useMemo(() => new AbortController(), []);
    const [dataHistory, setDataHistory] = useState();
    const urlPath = window.location.pathname;
    const id = urlPath.split("/").filter(Boolean).pop();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const ELEMENET_PER_PAGE = 20;

    const fetchHistories = useCallback(async () => {
        try {
            const results = await Api.get(`recouv/history/?company=${id}`, abortController.signal);

            if (results.results) {
                setLoading(false);
            }

            setDataHistory(results);

            const PAGE_NUMBER = Math.ceil(results.count / ELEMENET_PER_PAGE);
            setTotalPage(PAGE_NUMBER);
        } catch (error) {
            console.error("Error fetching organization history data:", error);
            setLoading(false);
        }
    }, [abortController]);

    const handleNextPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await nextPage(params);
            setPage(page + 1);
            setDataHistory(datas);
        }
        setLoading(false);
    };

    const handlePreviousPage = async (params) => {
        setLoading(true);
        if (params) {
            const datas = await previousPage(params);
            page > 1 ? setPage(page - 1) : setPage(1);
            setDataHistory(datas);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistories();
    }, [fetchHistories]);

    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-6">
                <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                    {loading ? (
                        <Skeleton count={20} />
                    ) : dataHistory ? (
                        <>
                            <table className="table table-report sm:mt-2">
                                <thead className="text-gray-800">
                                    <tr>
                                        <th className="whitespace-no-wrap">RECHERCHE</th>
                                        <th className="whitespace-no-wrap">DATE</th>
                                        <th className="whitespace-no-wrap">UTILISATEUR</th>
                                        <th className="whitespace-no-wrap">TYPE</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {dataHistory.results.map((item, index) => (
                                        <tr className="intro-x" key={index}>
                                            <td>
                                                <div className="flex text-gray-800">
                                                    {String.stringLength(item.request)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-gray-800">
                                                    {styleDate(item.created_at)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex text-gray-800">
                                                    {item.user?.username || "N/A"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex text-gray-800">
                                                    {item.type || "N/A"}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={dataHistory.count > 20 ? "px-5 py-3 flex justify-end text-right border-t border-gray-200" : "hidden"}>
                                <button
                                    onClick={() => handlePreviousPage(dataHistory.previous)}
                                    type="button"
                                    className="button flex w-36 items-center border bg-theme-1 text-white"
                                >
                                    <span>
                                        <Icons.ArrowLeft />
                                    </span>
                                    <span> Précédent </span>
                                </button>
                                <div className="bg-white text-gray-600 bold mx-5 p-3 box">
                                    {dataHistory.results.length ? <span>{page} / {totalPage}</span> : 0}
                                </div>
                                <button
                                    onClick={() => handleNextPage(dataHistory.next)}
                                    type="button"
                                    className="button flex w-36 items-center border bg-theme-1 text-white"
                                >
                                    <span> Suivant </span>
                                    <span>
                                        <Icons.ArrowRight />
                                    </span>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="intro-y text-center text-3xl text-gray-600 mt-3">
                            <p> Désolé, aucune information disponible. </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HistoriqueOrganisation;
