import { useCallback, useEffect, useMemo, useState } from "react";
import { Api } from "../services/Api";
import { String } from "../utils/String";
import { nextPage, previousPage } from "../utils/pagination";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Utils } from "../utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ParametreFacturation = () => {
    const [accountId, setAccountId] = useState(null);
    const [CreditApi, setCreditApi] = useState("");
    const [CreditNotif, setCreditNotif] = useState("");
    const [CreditDocument, setCreditDocument] = useState("");
    const [CreditDecision, setCreditDecision] = useState("");
    const [CreditRecherche, setCreditRecherche] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const Id = location.pathname.split("/").pop();

    useEffect(() => {
        const fetchCredits = async () => {
            setLoading(true);
            try {
                const res = await Api.get(`recouv/account/?company=${Id}`);
                const account_id_retrive = res[0].id;

                const response = await fetch(`https://api.datagreffe.ci/notifications/credit_rules/retrieve_by_company/?requesting_company=${Id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des crédits");
                }
                const result = await response.json();
                result.forEach(rule => {
                    switch (rule.action_type) {
                        case "search":
                            setCreditRecherche(rule.credit_cost || "");
                            break;
                        case "decision_justice":
                            setCreditDecision(rule.credit_cost || "");
                            break;
                        case "api":
                            setCreditApi(rule.credit_cost || "");
                            break;
                        case "document_constitutif":
                            setCreditDocument(rule.credit_cost || "");
                            break;
                        case "notification":
                            setCreditNotif(rule.credit_cost || "")
                            break;
                        default:
                            break;
                    }
                });

            } catch (error) {
                console.error("Erreur:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCredits();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const res = await Api.get(`recouv/account/?company=${Id}`);
        const account_id = res[0].id;

        setLoading(true);
        const data = {
            requesting_company: Id,
            rules: [
                {
                    action_type: "api",
                    credit_cost: CreditApi
                },
                {
                    action_type: "search",
                    credit_cost: CreditRecherche
                },
                {
                    action_type: "document_constitutif",
                    credit_cost: CreditDocument
                },
                {
                    action_type: "decision_justice",
                    credit_cost: CreditDecision
                },
                {
                    action_type: "notification",
                    credit_cost: CreditNotif
                },
            ],
        };

        try {
            const response = await fetch("https://api.datagreffe.ci/notifications/credit_rules/update_by_company/", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Utils.Auth.getSessionToken()}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'enregistrement des crédits");
            }
            navigate(-1);
            toast.success("Crédits mis à jour avec succès!");
        } catch (error) {
            console.error("Erreur:", error);
            toast.error("Une erreur est survenue");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-black">Crédit Notification</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={CreditNotif}
                            onChange={(e) => setCreditNotif(e.target.value)}
                            required
                            min="0"
                            step="any"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Crédit Api</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={CreditApi}
                            onChange={(e) => setCreditApi(e.target.value)}
                            required
                            min="0"
                            step="any"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Crédit Recherche détaillé</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={CreditRecherche}
                            onChange={(e) => setCreditRecherche(e.target.value)}
                            required
                            min="0"
                            step="any"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Crédit Documents constitutifs</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={CreditDocument}
                            onChange={(e) => setCreditDocument(e.target.value)}
                            required
                            min="0"
                            step="any"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-black">Crédit décisions de justice</label>
                        <input
                            type="number"
                            className="input w-full border p-2 rounded-lg focus:ring-2 focus:ring-theme-1 text-gray-800"
                            value={CreditDecision}
                            onChange={(e) => setCreditDecision(e.target.value)}
                            required
                            min="0"
                            step="any"
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-700"
                        disabled={loading}
                    >
                        {loading ? <Skeleton width={100} /> : "Enregistrer"}
                    </button>
                </div>
                <ToastContainer />

            </form>
        </div>
    );
};

export default ParametreFacturation;
